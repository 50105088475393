import Cart from './components/Cart'
const Vue = window.vue
const i18n = window.i18n

document.addEventListener('DOMContentLoaded', () => {
  const $els = document.querySelectorAll('.cart-product-list-wrapper')

  $els.forEach(($el) => {
    new Vue({
      i18n,
      el: $el,
      data: {},
      components: { Cart }
    })
  })
})
