<template>
  <div class="card cart-product-list w-100 p-2">
    <div v-if="!true" class="d-flex align-items-center flex-column">
      <h3>{{ $t('cart.there_are_no_items_in_the_shopping_cart') }}</h3>

      <a :href="productsUrl" class='btn btn-primary d-flex align-items-center'><span class='material-icons mr-1'>home</span><span>{{ $t('action.back_to_store') }}</span></a>
    </div>

    <div v-else>
      <div v-for="item in sharedState.items" :key="item.id" class="product">
        <figure class="product-image">
          <secure-image :src="item.image_url" :default-src="item.default_image_url"></secure-image>
        </figure>
        <h6 class="product-name" v-html="formatName(item)"></h6>
        <small class="product-specifications font-weight-light text-muted" :title="extractSpecifications(item)">{{ extractSpecifications(item) }}</small>
        <div class="product-quantity">
          <button type="button" class="btn btn-sm btn-light p-0 m-1" :title="$t('action.remove')" @click="(event) => decrementItemQuantity(event, item)">
            <span class="material-icons">remove</span>
          </button>
          <span class="product-quantity__value">{{ item.quantity }}</span>
          <button type="button" class="btn btn-sm btn-light p-0 m-1" :title="$t('action.add')" @click="(event) => incrementItemQuantity(event, item)">
            <span class="material-icons">add</span>
          </button>
        </div>
        <div class="product-actions">
          <button type="button" class="btn btn-link" @click="editItem(item)">{{ $t('action.edit') }}</button>
          <button type="button" class="btn btn-link" :data-question="$t('question.are_you_sure')" :data-cancel="$t('action.cancel')" :data-confirm="$t('action.remove')" @click="(event) => removeItem(event, item)">{{ $t('action.remove') }}</button>
        </div>
        <h6 class="product-price">{{ convertToCurrency(item.total) }}</h6>
      </div>

      <table class="table table-borderless w-100">
        <tbody>
          <tr>
            <td>
              {{ $t('order.subtotal') }}
            </td>
            <td class="text-right"><h6 class="mb-0">{{ convertToCurrency(sharedState.total) }}</h6></td>
          </tr>
          <tr v-show="defaultDeliveryCost !== 0">
            <td>
              {{ $t('order.delivery_cost') }}
            </td>
            <td class="text-right border-bottom"><h6 class="mb-0">{{ convertToCurrency(defaultDeliveryCost) }}</h6></td>
          </tr>
          <tr>
            <td>
              {{ $t('order.total') }}
            </td>
            <td class="text-right"><h6 class="mb-0">{{ convertToCurrency(sharedState.total + defaultDeliveryCost) }}</h6></td>
          </tr>
        </tbody>
      </table>

      <!-- Modal -->
      <div class="modal fade" id="itemFormModal" tabindex="-1" :aria-labelledby="$t('cart.items_edition_form')" aria-hidden="true" ref="modal" data-backdrop="false">
        <div class="modal-dialog modal-xl mb-5">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{ $t('product.editing') }}</h5>
              <button type="button" class="close" data-dismiss="modal" :aria-label="$t('action.close')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <item-form :out-cart="false" :init-item="selectedItem" :maximum-quantity="selectedItem.maximum_quantity" @update="updateItem"></item-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { wemeikPopup } from '../core'
import { convertToCurrency, calculateMaxQuantity } from '../../utils/functions'
import { disableButton, enableButton, getButtonTargetted } from '../../utils/buttons'
import ItemForm from './ItemForm'
import SecureImage from './SecureImage'

export default {
  props: {
    defaultDeliveryCost: { type: Number, default: 0 },
    productsUrl: { type: String, default: '#' }
  },
  // Propiedades o atributos del objeto. La ventaja es que son reactivas
  data () {
    return {
      sharedState: window.cartStore.state,
      selectedItem: {}
    }
  },
  components: { 'item-form': ItemForm, 'secure-image': SecureImage },
  computed: {
    hasItems () {
      return (Array.isArray(this.sharedState.items) && this.sharedState.items.length > 0) || this.sharedState.count > 0
    }
  },
  methods: {
    convertToCurrency: convertToCurrency,
    decrementItemQuantity (event, item) {
      if (item.quantity > 1) {
        item.quantity = parseFloat(item.quantity) - 1
        this.updateTotal(item)
      }
    },
    editItem (item) {
      this.selectedItem = Object.assign({}, this.selectedItem, item)
      window.jQuery(this.$refs.modal).modal('show')
    },
    extractSpecifications (item) {
      let specifications = ''

      specifications += item.additionals
        .filter((a) => a.checked)
        .map((a) => a.name)
        .concat(item.observations.filter((a) => a.checked).map((a) => a.name))
        .join(' + ')

      return specifications
    },
    formatName (item) {
      let formattedName = item.name

      if (item.fraction_id) {
        const selectedFraction = item.fractions.find(
          (fraction) => fraction.id === item.fraction_id
        )
        formattedName += ` <small class="text-muted">(${selectedFraction.description})</small>`
      }

      return formattedName
    },
    incrementItemQuantity (event, item) {

      item.quantity = calculateMaxQuantity(item.quantity, item.maximum_quantity)
      this.updateTotal(item)
    },
    removeItem (event, item) {
      const removeButton = getButtonTargetted(event.target)
      disableButton(removeButton)

      wemeikPopup({
        title:
          removeButton.dataset.question || this.$t('question.are_you_sure'),
        icon: 'warning',
        dangerMode: false,
        buttons: {
          cancel: removeButton.dataset.cancel || this.$t('action.cancel'),
          confirm: removeButton.dataset.confirm || removeButton.title
        }
      }).then((willRemove) => {
        if (willRemove) {
          window.cartStore.removeItem(item.id)
        } else {
          enableButton(removeButton)
        }
      })
    },
    updateItem (item) {
      window.cartStore.updateItem(item)
    },
    updateTotal (item) {
      item.total =
        item.quantity *
        (item.price +
          item.additionals.reduce(
            (accumulator, a) =>
              accumulator + (a.checked ? parseFloat(a.price) : 0),
            0
          ))
      this.updateItem(item)
    }
  }
}
</script>
