<template>
  <img :src="src" :alt="alt" :title="title" :class="className" ref="img" v-on:error="setDefault" loading="lazy" />
</template>

<script>

export default {
  props: {
    alt: { type: String, required: false },
    className: { type: String, required: false },
    defaultSrc: { type: String, required: false },
    src: { type: String, required: false },
    title: { type: String, required: false }
  },
  // Propiedades o atributos del objeto. La ventaja es que son reactivas
  data () {
    return {}
  },
  mounted () {
    if (!this.src && this.defaultSrc) {
      this.setDefault()
    }
  },
  methods: {
    setDefault () {
      if (this.defaultSrc && this.$refs.img) {
        this.$refs.img.src = this.defaultSrc
      }
    }
  }
}
</script>
