export default {
  about: 'Acerca de...',
  actions: 'Acciones',
  costs: 'Costos',
  dashboard: 'Tablero',
  false: 'Falso',
  finisheds: 'Acabados',
  help: 'Ayuda',
  hour: 'Hora',
  home: 'Inicio',
  list_of: 'Lista de',
  login: 'Inicio de Sesión',
  my_account: 'Mi Cuenta',
  my_dashboard: 'Mi tablero',
  new: 'Nuevo',
  newf: 'Nueva',
  no: 'No',
  notifications: 'Notificaciones',
  please_select_one_option: 'Por favor, seleccione una opción',
  pre_finisheds: 'Pre-Acabados',
  purchases: 'Compras',
  search: 'Búsqueda',
  subtotal: 'Subtotal',
  tools: 'Herramientas',
  total: 'Total',
  total_costs: 'Costos Totales',
  totals: 'Totales',
  true: 'Verdadero',
  users: 'Usuarios',
  value: 'Valor',
  values: 'Valores',
  yes: 'Sí',
  address: {
    home: 'Casa',
    work: 'Trabajo',
    favorite: 'Favorito',
    other: 'Otro',
    will_selected: 'La dirección sera seleccionada como predeterminada',
    selected: 'La dirección se ha seleccionado correctamente',
    chosen_one: 'Seleccionada'
  },
  alert: {
    success: '¡Bien Hecho!',
    error: '¡Error!',
    warning: '¡Advertencia!',
    info: '¡Información!',
    great_news: '¡Excelentes noticias!',
    heads_up: '¡Un momento!'
  },
  action: {
    add: 'Agregar',
    add_and_finish: 'Agregar y finalizar',
    add_ink: 'Agregar Tintas',
    add_row: 'Agregar fila',
    adjust_number_of_rows: 'Ajustar número de filas',
    backspace: 'Retroceder',
    back_to_store: 'Volver a la Tienda',
    cancel: 'Cancelar',
    select: 'Seleccionar',
    clean: 'Limpiar',
    close: 'Cerrar',
    close_parentheses: 'Cerrar Paréntesis',
    continue: 'Continuar',
    copy: 'Copiar',
    copied: 'Copiado',
    decrease: 'Disminuir',
    delete: 'Eliminar',
    disable: 'Inhabilitar',
    divide: 'Dividir',
    edit: 'Editar',
    enable: 'Habilitar',
    filter: 'Filtrar',
    finish: 'Finalizar',
    go_to: 'Ir a',
    increase: 'Incrementar',
    i_get_it: 'Entiendo',
    login: 'Iniciar Sesión',
    logout: 'Terminar la Sesión',
    move: 'Mover',
    multiply: 'Multiplicar',
    next: 'Siguiente',
    ok: 'Aceptar',
    only_finish: 'Solo finalizar',
    pause: 'Pausar',
    previous: 'Anterior',
    print: 'Imprimir',
    recalculate_costs: 'Recalcular Costos',
    refresh: 'Refrescar',
    register: 'Registrar',
    remove: 'Remover',
    restore: 'Restablecer',
    save: 'Guardar',
    save_observation: 'Guardar Observación',
    select_all: 'Seleccionar todos',
    send: 'Enviar',
    simulate: 'Simular',
    show_disabled_users: 'Mostrar usuarios inhabilitados',
    start: 'Iniciar',
    subtract: 'Restar',
    sum: 'Sumar',
    unselect_all: 'Deseleccionar todos',
    update: 'Actualizar',
    view: 'Ver',
    view_detail: 'Ver Detalle',
    view_purchase: 'Ver Compra',
    view_activities: 'Ver actividades',
    write_your_observation: 'Escribe tu observación'
  },
  additional: {
    list: 'Adiciones',
    warnings: {
      quantity_less_than_or_equal_to: 'La cantidad de adiciones debe ser menor o igual a ',
      quantity_more_than_or_equal_to: 'La cantidad de adiciones debe ser mayor o igual a '
    }
  },
  cart: {
    there_are_no_items_in_the_shopping_cart: 'Aún no has seleccionado nada'
  },
  error: {
    creating_order: 'No se ha podido registrar el pedido. Por favor, revisa todos los campos e intenta de nuevo.',
    loading_address: 'No se ha podido cargar o seleccionar correctamente la dirección, intentalo de nuevo.'
  },
  item: {
    presentation: 'Presentación',
    quantity: 'Cantidad',
    warnings: {
      quantity_less_than_or_equal_to_zero: 'La cantidad debe ser mayor a cero (0)'
    }
  },
  observation: {
    one: 'Observación',
    list: 'Observaciones'
  },
  order: {
    add_more_items: 'Necesitas agregar más items',
    all_products_qualified: 'Ya has calificado todos los productos de esta orden',
    change_datetime: 'Debes cambiar la fecha y hora de entrega',
    check_your_money: 'Por favor, revisa con cuanto pagarás',
    check_your_phone: 'Por favor revisa tu número celular',
    do_you_want_add_this_product: '¿Quieres agregar el producto y finalizar el pedido?',
    delivery_cost: 'Costo de Envío',
    on_way_time: 'Entre %{start_time} y %{end_time} minutos, estaremos contigo',
    phone_has_less_than_ten_digits: 'El teléfono tiene menos de diez dígitos',
    qualified: "¡Orden calificada!",
    select_datetime: 'Debes seleccionar la FECHA y HORA de entrega',
    subtotal: 'Subtotal',
    total: 'Total',
    we_need_code: 'Necesitamos el código de tu mesa',
    we_sorry: 'Lo lamentamos',
    errors: {
      dont_datetime_before_than: 'No puedes selecionar una FECHA y HORA de entrega antes del %{datetime}.',
      missing_address: 'Falta la Dirección de Envío',
      missing_locality: 'Falta la Ciudad de Envío'
    },
    messages: {
      add_more_than: 'Agrega algo de %{price} o más valor, para que podamos entregar tu pedido.',
      enter_code: 'Por favor ingresa el <b>código</b> que debe estar impreso cerca a tu mesa.',
      enter_name: 'Por favor dinos tu nombre',
      enter_value: 'Ingresa un valor igual o mayor a %{total}',
      maximum_quantity_of_items: 'No podemos llevarte esa cantidad de items. El máximo es de %{maximum_quantity} items.',
      select_address: 'Debes ingresar la dirección en la que deseas recibir el pedido.',
      select_locality: 'Debes seleccionar la ciudad o municipio.',
    }
  },
  processing: 'Procesando...',
  question: {
    are_you_sure: '¿Estas seguro(a)?'
  },
  product: {
    editing: 'Editando Item',
    rate: 'Califique el producto',
    review_sent: '¡Tu reseña ha sido enviada!'
  },
  warning: {
    please_wait: 'Por favor espera.',
    redirecting: 'Estas siendo redireccionado...'
  }
}
