<template>
  <div class="container-fluid">
    <div v-if="typeof product === 'object' && Object.keys(product).length > 0">
      <div class="row mx-0 mx-md-1 mt-0 mt-md-3">
        <div class="col-12 col-md-6 mb-2 px-0">
          <div class="card">
            <div v-if="product.images && product.images.length > 0" id="productImagesSlides" class="carousel slide" data-ride="carousel">
              <div class="carousel-inner">
                <div v-for="(image, index) in product.images" :key="index" :class="`carousel-item ${index === 0 ? 'active' : ''}`">
                  <secure-image :src="image" class="d-block w-100 img-fluid animate__animated animate__bounceIn" :default-src="product.default_image_url"></secure-image>
                </div>
              </div>
              <a class="carousel-control-prev" href="#productImagesSlides" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">{{ $t('action.previous') }}</span>
              </a>
              <a class="carousel-control-next" href="#productImagesSlides" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">{{ $t('action.next') }}</span>
              </a>
            </div>
            <div v-else class="text-center">
              <img :src="product.default_image_url" alt="" class="img-fluid p-1" />
            </div>
            <p class="text-capitalize text-muted text-justify p-3">{{ product.description }}</p>
            <h5 class="text-primary py-1 px-3">{{ convertToCurrency(unitPrice) }}</h5>
          </div>
        </div>

        <div class="col-12 col-md-6">
          <div class="card mb-2">
            <h3 class="text-capitalize p-2">{{ product.name }}</h3>
          </div>

          <div v-if="hasFractions">
            <h5>{{ $t('item.presentation') }}</h5>

            <select class="custom-select" v-model="product.fraction_id">
              <option v-for="fraction in product.fractions" :key="fraction.id" :value="fraction.id">{{ `${convertToCurrency(fraction.price)} x ${fraction.description} ${fraction.quantity > 1 ? `(${fraction.quantity} unid.` : ''}` }} </option>
            </select>
          </div>

          <div v-if="hasAdditionals">
            <h5>{{additionalsTitle}}</h5>

            <div class="table-responsive card mb-2 p-1">
              <table class="table table-borderless table-hover mb-0 w-100">
                <tbody>
                  <tr v-for="additional in additionals" :key="`additional-${additional.id}`" style="vertical-align: center;">
                    <td>
                      <div class="custom-control custom-checkbox">
                        <input :disabled="limitAdditionals && !additional.checked" type="checkbox" @click="toggleAdditional(additional.checked)" class="custom-control-input" :id="`additional-${additional.id}`" v-model="additional.checked">
                        <label class="custom-control-label w-100" :for="`additional-${additional.id}`">{{ additional.name }}</label>
                      </div>
                    </td>
                    <td class="text-right text-muted mb-2" style="min-width: 100px;">
                      +{{ convertToCurrency(additional.price) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div v-if="hasObservations">
            <h5>{{ $t('observation.list') }}</h5>

            <div class="table-responsive card mb-2 p-1">
              <table class="table table-borderless table-hover mb-0 w-100">
                <tbody>
                  <tr v-for="observation in observations" :key="`observation-${observation.id}`">
                    <td>
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" :id="`observation-${observation.id}`" v-model="observation.checked">
                        <label class="custom-control-label w-100" :for="`observation-${observation.id}`">{{ observation.name }}</label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>
      </div>

      <div class="row">
        <div class="col-12 offset-lg-5 col-lg-3 mb-2">
          <div class="d-flex">
            <div class="input-group">
              <div class="input-group-prepend">
                <button type="button" class="btn btn-sm btn-outline-dark" :title="$t('action.decrease')" @click="decrementQuantity"><span class="material-icons">remove</span></button>
              </div>
              <input type="number" class="form-control h-100" v-model="quantity" min="0" :aria-label="$t('item.quantity')" ref="quantityField" />
              <div class="input-group-append">
                <button type="button" class="btn btn-sm btn-outline-dark" :title="$t('action.increase')" @click="incrementQuantity"><span class="material-icons">add</span></button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 mb-2">
          <button type="button" class="btn btn-primary d-flex align-items-center justify-content-between w-100" :title="$t('action.save')" @click="updateItem">
            <div class="d-flex align-items-center">
              <span class="material-icons mr-1">save</span>
              <span class="mr-1">{{ $t('action.save') }}</span>
            </div>
            <span>{{ convertToCurrency(total) }}</span>
          </button>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import { convertToCurrency, calculateMaxQuantity } from '../../utils/functions'
import { wemeikPopup } from '../core'
import cloneDeep from 'lodash/cloneDeep'
import SecureImage from './SecureImage'
import additionals from '../mixins/additionals'

export default {
  props: {
    initItem: { type: Object, required: true },
    outCart: { type: Boolean, default: false},
    maximumQuantity: { type: Number}
  },
  // Propiedades o atributos del objeto. La ventaja es que son reactivas
  data () {
    return {
      additionals: [],
      hasAdditionals: false,
      hasFractions: false,
      hasObservations: false,
      observations: [],
      product: {
        fraction_id: null,
        price: 0
      },
      quantity: 1,
      total: 0,
      unitPrice: 0
    }
  },
  components: { 'secure-image': SecureImage },
  mixins: [additionals],
  watch: {
    initItem: {
      deep: true,
      handler () {
        this.additionalsCount = 0
        this.limitAdditionals = false
        this.setProduct(this.initItem)
        this.recalculateAdditionals()
      }
    },
    product: {
      deep: true,
      handler () {
        this.recalculateTotal()
      }
    },
    additionals: {
      deep: true,
      handler () {
        this.recalculateTotal()
      }
    },
    quantity () {
      this.recalculateTotal()
    }
  },
  methods: {
    convertToCurrency: convertToCurrency,
    decrementQuantity () {
      const currentQuantity = parseFloat(this.quantity)
      this.quantity = currentQuantity > 0 ? currentQuantity - 1 : currentQuantity
    },
    incrementQuantity () {
      this.quantity = calculateMaxQuantity(this.quantity, this.maximumQuantity)
    },
    recalculateTotal () {
      if (this.hasFractions) {
        const selectedFraction = this.product.fractions.find((fraction) => fraction.id === this.product.fraction_id)
        this.unitPrice = parseFloat(selectedFraction.price)
      }

      const quantity = parseFloat(this.quantity)
      let total = quantity * this.unitPrice

      if (this.hasAdditionals) {
        const additionalsTotal = quantity * this.additionals.reduce((accumulator, a) => accumulator + (a.checked ? parseFloat(a.price) : 0), 0)
        total += additionalsTotal
      }

      this.total = total
    },
    setProduct (item = {}) {
      this.product = cloneDeep(item)
      this.additionals = this.product.additionals
      this.observations = this.product.observations
      this.product.max_additionals = item.max_additionals === 0 ? item.additionals.length : item.max_additionals
      
      this.hasAdditionals = Array.isArray(this.product.additionals) && this.product.additionals.length > 0
      this.hasFractions = Array.isArray(this.product.fractions) && this.product.fractions.length > 0
      this.hasObservations = Array.isArray(this.product.observations) && this.product.observations.length > 0

      if (this.hasFractions && !this.product.fraction_id) {
        this.product.fraction_id = this.product.fractions[0].id
        this.unitPrice = parseFloat(this.product.fractions[0].price)
      } else {
        this.unitPrice = parseFloat(this.product.price)
      }

      this.quantity = this.product.quantity
    },
    updateItem () {
      if (this.quantity <= 0) {
        wemeikPopup({
          title: this.$t('alert.warning'),
          text: this.$t('item.warnings.quantity_less_than_or_equal_to_zero'),
          icon: 'warning'
        })
          .then(() => {
            this.$refs.quantityField.focus()
          })

        return
      }

      if (this.additionalsCount < this.product.min_additionals) {
        wemeikPopup({
          title: this.$t('alert.warning'),
          text: this.$t('additional.warnings.quantity_more_than_or_equal_to') + this.product.min_additionals,
          icon: 'warning'
        })
          .then(() => {
            this.$refs.quantityField.focus()
          })

        return
      }

      const item = Object.assign(this.product, {
        additionals: this.additionals,
        observations: this.observations,
        price: this.unitPrice,
        quantity: this.quantity,
        total: this.total
      })

      this.$emit('update', item)
      if (this.outCart) {
        window.jQuery('#itemFormModalAccompaniment').modal('hide')  
      } else {
        window.jQuery('#itemFormModal').modal('hide')
      }
      
    }
  }
}
</script>
