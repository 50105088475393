import Vue from 'vue/dist/vue.min'
import VueI18n from 'vue-i18n'
import es from './i18n/locales/es'
import swal from 'sweetalert'

Vue.use(VueI18n)

const i18n = new VueI18n({
  messages: { es },
  locale: 'es'
})


const wemeikPopup = ( options ) => {
  if (options.buttons !== undefined) {
    if (options.buttons.cancel !== undefined) {
      if (typeof options.buttons.cancel === 'string') {
        options.buttons.cancel = {
          text: options.buttons.cancel,
          visible: true
        }
      }
      options.buttons.cancel.className = 'btn btn-outline-primary'
    }
    if (options.buttons.confirm !== undefined) {
      if (typeof options.buttons.confirm === 'string') {
        options.buttons.confirm = {
          text: options.buttons.confirm
        }
      }
    }  
  } else {
    options.buttons = {}
    options.buttons.confirm = {
      text: 'OK'
    }
  }
  if (options.dangerMode) {
    options.buttons.confirm.className = 'btn btn-danger'
  } else {
    options.buttons.confirm.className = 'btn btn-primary'
  }
  setTimeout(() => {
    window.jQuery('.swal-button').removeClass('swal-button swal-button--cancel swal-button--confirm swal-button--danger')
  }, 100)
  return swal(options)
}
wemeikPopup.close = function () {}

export { Vue, i18n, wemeikPopup }
