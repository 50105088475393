import { wemeikPopup } from '../vue/core'
import { getSessionStorageValue, setSessionStorageValue } from './storage'

const getCurrentPosition = () => {
  return new Promise(function (resolve, reject) {
    navigator.geolocation.getCurrentPosition((position) => {
      resolve({ latitude: position.coords.latitude, longitude: position.coords.longitude })
    }, (error) => {
      console.error('Error getting position from HTML5: ', error)
      resolve(getCurrentPositionFromGoogle())
    })
  })
}

const getCurrentPositionFromGoogle = () => {
  return fetch('https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyAO2sgburBqlqlW25qPIEiXWJlvkGj-qyM', {
    method: 'POST',
    mode: 'cors'
  })
    .then((response) => {
      return response.json()
    })
    .then((data) => {
      return { latitude: data.location.lat, longitude: data.location.lng }
    })
    .catch((error) => {
      console.error('Error getting position from Google API: ', error)
      new Error('Error getting position from Google API')
    })
}

const askByGeolocation = () => {
  return wemeikPopup({
    title: 'Por favor, compartenos tu ubicación',
    text: 'Así podremos ayudarte a seleccionar la sucursal más cercana.',
    icon: 'info',
    buttons: {
      cancel: 'Cancelar',
      confirm: 'Compartir'
    },
    dangerMode: false
  })
    .then((accepted) => {
      if (accepted) {
        return getCurrentPosition()
      } else {
        return getCurrentPositionFromGoogle()
      }
    })
}

const calculateCustomerLocation = () => {
  return new Promise((resolve, reject) => {
    const position = getSessionStorageValue('position')

    if (position) {
      resolve(JSON.parse(position))
    } else if (navigator.permissions) {
      navigator.permissions.query({
        name: 'geolocation'
      })
        .then((permission) => {
          if (permission.state === 'granted') {
            resolve(getCurrentPosition())
          } else {
            resolve(askByGeolocation())
          }
        })
        .catch((error) => {
          console.error('Error', error)
          reject(error)
        })
    } else {
      resolve(askByGeolocation())
    }
  })
}

const saveCurrentPosition = (position) => {
  setSessionStorageValue('position', JSON.stringify(position))
}

export { calculateCustomerLocation, saveCurrentPosition }
