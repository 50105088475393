import { wemeikPopup } from '../packs/vue/core'

class UploadButton {
  constructor (input) {
    this.input = input
    this.allowedExtensions = ['gif', 'jpg', 'jpeg', 'png']
    this.init()
  }

  createImageElementFromInputFile () {
    if (this.input.files && this.input.files[0]) {
      const reader = new FileReader()
      const img = document.createElement('img')

      reader.onload = function (e) {
        img.setAttribute('src', e.target.result)
      }

      reader.readAsDataURL(this.input.files[0])

      return img
    }

    return null
  }

  destroy () {
    this.input.removeEventListener('change', this.process.bind(this))
  }

  init () {
    this.input.addEventListener('change', this.process.bind(this))
    this.updateAllowedExtensions()
  }

  isValid () {
    const fileExtension = this.input.value.substring(this.input.value.lastIndexOf('.') + 1).toLowerCase()

    if (this.input.files) {
      const file = this.input.files[0]
      if (!file) {
        wemeikPopup({
          title: 'Error',
          text: 'No se ha seleccionado ningún archivo.',
          icon: 'error',
          dangerMode: true
        })

        return false
      }

      if ((file.size / 1000000) > 4) {
        wemeikPopup({
          title: 'Error',
          text: 'Este archivo superá el tamaño máximo permitido de 4 MB',
          icon: 'error',
          dangerMode: true
        })

        return false
      }
    } else {
      wemeikPopup({
        title: 'Error',
        text: 'Al parecer el navegador no soporta esta característica.',
        icon: 'error',
        dangerMode: true
      })

      return false
    }

    if (!this.allowedExtensions.includes(fileExtension)) {
      this.input.value = ''
      wemeikPopup({
        title: 'Error',
        text: `Revisa que el archivo sea uno de estos formatos (${this.allowedExtensions.join(', ')})`,
        icon: 'error',
        dangerMode: true
      })

      return false
    }

    return true
  }

  process () {
    if (this.isValid()) {
      const output = document.querySelector(this.input.dataset.output)
      if (output !== null && output !== undefined) {
        this.printOutput(output)
      }

      const previewer = document.querySelector(this.input.dataset.previewer)
      if (previewer !== null && previewer !== undefined) {
        this.showPreview(previewer)
      }
    } else {
      this.input.value = ''
    }
  }

  printOutput (output) {
    let fileName = ''
    if (this.input.files && this.input.files.length > 1) {
      fileName = (this.input.getAttribute('data-multiple-caption') || '').replace('{count}', this.input.files.length)
    } else {
      fileName = this.input.value.split('\\').pop()
    }

    output.innerHTML = fileName
  }

  showPreview (previewer) {
    const img = this.createImageElementFromInputFile()
    this.input.dataset.classes.split(' ').forEach(function (c) {
      img.classList.add(c)
    })
    previewer.innerHTML = ''
    previewer.append(img)
  }

  updateAllowedExtensions () {
    try {
      if (this.input.dataset.additionalExtensions) {
        const additionalExtensions = this.input.dataset.additionalExtensions.split(',')
        if (additionalExtensions.length > 0) {
          this.allowedExtensions = this.allowedExtensions.concat(additionalExtensions)
        }
      }
    } catch (error) {
      console.error('Error getting additional extensions: ', error)
    }
  }
}

export default UploadButton
