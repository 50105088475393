import Rails from '@rails/ujs'
import { DateTime } from 'luxon'
import { getLocalStorageValue, setLocalStorageValue } from '../utils/storage'
const Vue = window.vue

const $el = document.getElementById('activeCampaignWrapper')

if ($el) {
  const CAMPAIGN_URL = $el.dataset.url
  const CLICK_CAMPAIGN_URL = $el.dataset.clickUrl

  new Vue({
    el: $el,
    data: {
      campaign: {}
    },
    mounted () {
      if (window.location.pathname !== '/') {
        this.loadCampaign()
      }
    },
    watch: {
      campaign: {
        deep: true,
        handler () {
          if (this.campaign.id) {
            this.showCampaign()
          }
        }
      }
    },
    methods: {
      goToCampaign () {
        this.registerClick()
        this.hideCampaign()
      },
      loadCampaign () {
        const self = this
        Rails.ajax({
          url: CAMPAIGN_URL,
          type: 'GET',
          dataType: 'json',
          success: (campaign) => {
            self.campaign = campaign
          },
          error: (error) => {
            console.error('Error loading campaign: ', error)
          }
        })
      },
      hideCampaign () {
        window.jQuery('#campaignModal').modal('hide')
      },
      isTimeToShowAgain () {
        const lastCampaignTimeStored = getLocalStorageValue('lastCampaignTime')

        if (lastCampaignTimeStored) {
          const lastCampaignTime = DateTime.fromMillis(parseInt(lastCampaignTimeStored))
          const timeElapsed = DateTime.now().diff(lastCampaignTime, ['minutes'])

          if (timeElapsed.minutes <= 20) {
            return false
          }
        }

        return true
      },
      registerClick () {
        const self = this
        Rails.ajax({
          url: CLICK_CAMPAIGN_URL,
          type: 'POST',
          dataType: 'json',
          success: (response) => {},
          error: (error) => {
            console.error('Error registering click: ', error)
          },
          complete: () => {
            if (this.campaign.link) {
              window.location.href = self.campaign.link
            }
          }
        })
      },
      showCampaign () {
        if (this.isTimeToShowAgain()) {
          window.jQuery('#campaignModal').modal()
          setLocalStorageValue('lastCampaignTime', DateTime.now().toMillis())
        }
      }
    }
  })
}
