import cartStore from './vue/stores/cart'

window.cartStore = cartStore

import './vue/cart_counter'
import './vue/cart_wrapper'

document.addEventListener('DOMContentLoaded', () => {
  // Cart Sidebar
  const closeSidebarCartButton = document.querySelector('.close-sidebar-cart')
  if (closeSidebarCartButton) {
    closeSidebarCartButton.addEventListener('click', () => {
      window.cartStore.closeSideCart()
    })
  }

  const $sidebarCart = window.jQuery('.sidebar-cart')
  if ($sidebarCart.length > 0) {
    document.querySelector('.sidebar-cart-overlay').addEventListener('click', (e) => {
      if (!$sidebarCart.is(e.target) && $sidebarCart.has(e.target).length === 0) {
        window.cartStore.closeSideCart()
      }
    })
  }
  // Cart Sidebar
})
