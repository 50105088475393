const additionals = {
	data: function () {
		return {
			additionalsCount: 0,
    	limitAdditionals: false
		}
	},
	computed: {
    additionalsTitle () {
      let title = this.$t('additional.list')
      if (this.product.max_additionals > 0) {
        if (this.product.min_additionals === 0) {
          title += ` (max. ${this.product.max_additionals})`
        } else {
          title += ` (min. ${this.product.min_additionals} - max. ${this.product.max_additionals})`
        }
      }
      return title
    }
  },
  methods: {
  	recalculateAdditionals: function () {
	    this.additionals.forEach((additional) => {
	      if (additional.checked) {
	        this.additionalsCount ++
	      }
	    })
	    if (this.additionalsCount >= this.product.max_additionals) {
	      this.limitAdditionals = true
	    }
  	},
  	toggleAdditional: function (checked) {
      if (checked) {
        this.additionalsCount --
      } else {
        this.additionalsCount ++
      }
      if (this.additionalsCount >= this.product.max_additionals) {
        this.limitAdditionals = true
      } else {
        this.limitAdditionals = false
      }
    }
  }
}

export default additionals
