import { getLocalStorageValue, setLocalStorageValue } from './storage'

class Db {
  items = getLocalStorageValue('selectedItems') === null ? [] : JSON.parse(getLocalStorageValue('selectedItems'))
  loadItems = new Promise((resolve, reject) => {
    resolve(this.items)
  })
  
  removeItem = function(id) {
    return new Promise((resolve, reject) => {
      const index = this.items.findIndex(a => a.id === id)
      this.items.splice(index, 1)
      setLocalStorageValue('selectedItems', JSON.stringify(this.items))
      resolve(this.items)
    })
  }

  saveItem (item) {
    this.items.push({
      id: item.product_id + Date.now(),
      product_id: item.product_id,
      item_id: item.id,
      fraction_id: item.fraction_id,
      name: item.name,
      description: item.description,
      quantity: parseFloat(item.quantity),
      price: parseFloat(item.price),
      total: parseFloat(item.total || 0),
      image_url: item.image_url,
      images: item.images,
      default_image_url: item.default_image_url,
      min_additionals: item.min_additionals,
      max_additionals: item.max_additionals,
      maximum_quantity: item.maximum_quantity,
      fractions: item.fractions,
      additionals: item.additionals,
      observations: item.observations
    })
    setLocalStorageValue('selectedItems', JSON.stringify(this.items))
  }

  truncateItems () {
    this.items = []
    setLocalStorageValue('selectedItems', JSON.stringify(this.items))
  }

  updateItem (item) {
    const index = this.items.findIndex(a => a.id === item.id)
    let updatedItem = this.items[index]
    updatedItem.fraction_id = item.fraction_id
    updatedItem.quantity = parseFloat(item.quantity)
    updatedItem.price = parseFloat(item.price),
    updatedItem.total = parseFloat(item.total),
    updatedItem.observations = item.observations,
    updatedItem.additionals = item.additionals
    this.items[index] = updatedItem
    setLocalStorageValue('selectedItems', JSON.stringify(this.items))
  }
}

const MyDb = new Db()

export default MyDb
