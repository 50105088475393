import { convertToCurrency } from '../utils/functions'
const Vue = window.vue

document.addEventListener('DOMContentLoaded', () => {
  const $els = document.querySelectorAll('.cart-icon, .finish-order')

  $els.forEach(($el) => {
    new Vue({
      el: $el,
      data: {
        sharedState: window.cartStore.state
      },
      methods: {
        openSideCart () {
          window.cartStore.openSideCart()
        },
        convertToCurrency: convertToCurrency
      }
    })
  })
})
