// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


(async () => {

  const { Vue, i18n } = await import('./vue/core')

  window.vue = Vue
  window.i18n = i18n

  await require('./cart')
})()

//import './sentry'
import { switchToCloseIcon } from './utils/buttons'
import { createPassiveAlert, createToast } from './utils/functions'
import { calculateCustomerLocation, saveCurrentPosition } from './utils/geolocation'
import UploadButton from '../common/upload_button.js'


require('@rails/ujs').start()
require('@rails/activestorage').start()
// require('channels')
window.jQuery = require('jquery/dist/jquery')
require('popper.js')
require('bootstrap')
window.createPassiveAlert = createPassiveAlert
window.createToast = createToast

window.calculateCustomerLocation = calculateCustomerLocation
window.saveCurrentPosition = saveCurrentPosition

setTimeout( () => {
  require('./vue/campaign')
}, 5000 )

document.addEventListener('DOMContentLoaded', () => {
  window.jQuery('.carousel').carousel({
    interval: 5000
  });

  [].forEach.call(document.querySelectorAll('.switch-to-close-icon'), (el) => {
    el.addEventListener('click', (event) => {
      switchToCloseIcon(event.target)
    })
  })

  // Loop over them and prevent submission
  Array.prototype.filter.call(document.querySelectorAll('.needs-validation'), (form) => {
    form.addEventListener('submit', (event) => {
      if (form.checkValidity() === false) {
        event.preventDefault()
        event.stopPropagation()
      }
      form.classList.add('was-validated')
    }, false)
  })

  const inputFiles = document.querySelectorAll('input.inputfile')
  Array.prototype.forEach.call(inputFiles, function (input) {
    new UploadButton(input)
  })

  // Automatic close success alerts after 10 secs
  setTimeout(function () {
    window.jQuery('.flash-alert').find('.alert.alert-success').alert('close')
  }, 10000)
})
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
