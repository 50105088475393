import { wemeikPopup } from '../vue/core'
import Rails from '@rails/ujs'

const getButtonTargetted = function (target) {
  const tagName = target.tagName.toLocaleLowerCase()
  return tagName === 'button' || tagName === 'a' ? target : tagName === 'form' ? target.querySelector('button[type="submit"], input[type="submit"]') : target.parentElement
}

const deleteRecord = function (event) {
  event.preventDefault()
  Rails.stopEverything(event)
  const element = getButtonTargetted(event.target)
  const title = element.dataset.title
  const message = element.dataset.message

  wemeikPopup({
    title: title || '¿Está usted seguro?',
    text: message,
    icon: 'warning',
    dangerMode: true,
    buttons: {
      cancel: element.dataset.cancelButton || 'Cancelar',
      confirm: element.dataset.confirmButton || 'Eliminar'
    }
  })
    .then((willDelete) => {
      if (willDelete) {
        Rails.ajax({
          url: element.href,
          type: 'delete',
          dataType: 'html',
          error: function (error) {
            console.error('Error deleting a record: ', error)
          }
        })
      }
    })

  return false
}

const disableButton = function (button) {
  const $b = window.jQuery(button)
  $b.children().hide()
  $b.append('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span class="sr-only">Cargando...</span>')
  $b.attr('disabled', 'disabled')
}

const enableButton = function (button) {
  const $b = window.jQuery(button)
  $b.find('span.spinner-border, span.sr-only').remove()
  $b.children().show()
  $b.removeAttr('disabled')
}

const getButtonWrapper = function (target) {
  const tagName = target.tagName.toLocaleLowerCase()
  return tagName !== 'i' && tagName !== 'span' ? target : target.parentElement
}

const switchToCloseIcon = (target) => {
  const wrapper = getButtonTargetted(target)
  const substituteIcon = wrapper.dataset.replaceIcon

  if (target.tagName.toLocaleLowerCase() === 'span' && target.className.includes('material-icons')) {
    wrapper.dataset.replaceIcon = target.innerText
    target.innerText = substituteIcon || 'close'
  } else {
    const icon = wrapper.querySelector('span.material-icons')
    if (icon) {
      wrapper.dataset.replaceIcon = icon.innerText
      icon.innerText = substituteIcon || 'close'
    }
  }
}

export { deleteRecord, disableButton, enableButton, getButtonTargetted, getButtonWrapper, switchToCloseIcon }
