import { wemeikPopup } from '../vue/core'

import { Settings, DateTime } from 'luxon'

Settings.defaultLocale = 'es'

const addZeroToLeft = function (value) {
  return value > 9 ? value : '0' + value
}

const alertError = function (error, description = null, status = null) {
  const message = error.message || error.error
  if (message) {
    wemeikPopup({
      title: 'Error',
      text: message,
      icon: 'error'
    })
  } else if (error !== null && typeof error === 'object' && Object.keys(error).length > 0) {
    const list = document.createElement('ul')
    let items = ''
    Object.keys(error).forEach(function (key) {
      items += `<li>${error[key][0]}</li>`
    })
    list.innerHTML = items

    wemeikPopup({
      title: 'Error',
      content: list,
      icon: 'error'
    })
  } else {
    console.error(description || 'Error from alert: ', error)
  }

  if (status === 'Unauthorized') {
    setTimeout(() => {
      location.reload()
    }, 5000)
  }
}

const capitalize = function (s) {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const convertToCurrency = function (val) {
  if (!val) return '$ 0'
  let isNegative = false
  const str = val.toString().trim()
  let base = str
  if (str.charAt(0) === '-') {
    base = str.substr(1, str.length).trim()
    isNegative = true
  }
  const splitedVal = base.split('.')
  const reversedVal = splitedVal[0].split('').reverse()
  let formattedVal = ''
  reversedVal.forEach((c, i) => {
    formattedVal += c + ((i + 1) % 3 === 0 && reversedVal[i + 1] !== undefined ? '.' : '')
  })
  return `$ ${isNegative ? '-' : ''}` + (splitedVal[1] !== undefined && parseInt(splitedVal[1]) > 0 ? `${formattedVal.split('').reverse().join('')},${splitedVal[1].slice(0, 2)}` : formattedVal.split('').reverse().join(''))
}

const convertToNumber = function (val) {
  if (!val) return 0
  return typeof val === 'string' ? parseFloat(val.replace(/[\\$.]/g, '').replace(',', '.')) : typeof val === 'number' ? val : 0
}

const createPassiveAlert = function (title, message, type = 'success', id = '') {
  const alertEl = document.getElementById(id)
  if (alertEl) {
    alertEl.querySelector('div.alert-message').innerHTML = `<p>${message}</p>`
  } else {
    const alertContainer = document.querySelector('.flash-alert')
    if (alertContainer) {
      let icon = '<span class="material-icons">check_circle</span>'
      switch (type) {
        case 'info':
          icon = '<span class="material-icons">info</span>'
          break
        case 'warning':
          icon = '<span class="material-icons">warning</span>'
          break
        case 'error':
          icon = '<span class="material-icons">bug_report</span>'
          break
      }

      alertContainer.innerHTML +=
        `<div id="${id}" class="alert alert-${type === 'error' ? 'danger' : type} alert-dismissible fade show mb-3" role="alert">
          <h4 class="alert-heading">${icon}&nbsp;${title}</h4>
          <hr class="my-2" />
          <div class="alert-message"><p>${message}</p></div>
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>`
    }
  }
}

const createToast = function ({ title, content, type = 'primary', actionUrl = '', delay = 10000 }) {
  const toastContainer = document.getElementById('toast-list')
  if (toastContainer) {
    let icon = '<span class="material-icons text-info">info</span>'
    let colorClass = ''

    switch (type) {
      case 'info':
        icon = '<span class="material-icons">info</span>'
        colorClass = 'bg-info text-white'
        break
      case 'warning':
        icon = '<span class="material-icons">warning</span>'
        colorClass = 'bg-warning text-white'
        break
      case 'danger':
      case 'error':
        icon = '<span class="material-icons">bug_report</span>'
        colorClass = 'bg-danger text-white'
        break
      case 'success':
        icon = '<span class="material-icons">check_circle</span>'
        colorClass = 'bg-success text-white'
        break
    }

    const id = `toast-${Math.floor(Math.random() * 10000)}`

    toastContainer.innerHTML +=
      `<div id="${id}" class="toast" role="alert" aria-live="assertive" aria-atomic="true" data-delay="${delay}">
        <div class="toast-header text-capitalize ${colorClass}">
          ${icon}
          <strong class="ml-1 mr-auto">${title}</strong>
          <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Cerrar" onclick="window.jQuery('#${id}').toast('hide');">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="toast-body">
          ${content}
          ${actionUrl ? `<br/><a class="btn btn-sm btn-${type} mt-2" href="${actionUrl}"><i class="fas fa-eye mr-1"></i> Ver</a>` : ''}
        </div>
      </div>`

    window.jQuery(`#${id}`).toast('show')
    setTimeout(() => {
      try {
        const toastEl = window.jQuery(`#${id}`)
        toastEl.toast('hide')
        toastEl.remove()
      } catch (error) {
        console.error('Error closing toast ', error)
      }
    }, delay)
  }
}

const cutDates = function (time) {
  let itime = time
  if (typeof itime === 'string') {
    itime = parseInt(itime)
  }

  const date = new Date(itime * 1000)
  const ct = new Date()

  if (ct.getFullYear() !== date.getFullYear() || ct.getMonth() !== date.getMonth() || ct.getDate() !== date.getDate()) {
    return dateTimeToString(date)
  }

  return timeToString(date)
}

const diffHours = function (dt2, dt1) {
  let diff = (dt2.getTime() - dt1.getTime()) / 1000
  diff /= (60 * 60)
  return Math.abs(Math.round(diff))
}

const dateTimeToString = function (time) {
  let d = typeof time === 'string' ? parseInt(time) : time
  if (typeof d === 'number') {
    d = new Date(d * 1000)
  }

  const month = addZeroToLeft(d.getMonth() + 1)
  const day = addZeroToLeft(d.getDate())

  return `${day}-${month}-${d.getFullYear()} ${timeToString(d)}`
}

const formatDatetime = function (time) {
  let itime = time
  if (typeof itime === 'string') {
    itime = parseInt(itime)
  }
  const mDate = DateTime.fromSeconds(itime)
  return `${capitalize(mDate.toFormat('cccc d'))} de ${capitalize(mDate.toFormat('LLLL y'))} a las ${mDate.toFormat('hh:mm a')}`
}

const suspensivePoints = function (text, maxLength) {
  if (text.length > maxLength) {
    return `${text.substr(0, maxLength)}...`
  }

  return text
}

const timeToString = function (time) {
  let d = time
  if (typeof time === 'number') {
    d = new Date(time * 1000)
  }

  const hour = addZeroToLeft(d.getHours() > 12 ? d.getHours() - 12 : d.getHours() === 0 ? 12 : d.getHours())
  const minute = addZeroToLeft(d.getMinutes())
  const acronym = d.getHours() >= 12 ? 'pm' : 'am'

  return `${hour}:${minute} ${acronym}`
}

const calculateMaxQuantity = function (currentValue, maxValue) {
  const currentQuantity = parseFloat(currentValue)
  const maximumQuantity = (maxValue === 0 || maxValue === undefined) ? 9999 : maxValue
  return currentQuantity < maximumQuantity ? currentQuantity + 1 : currentQuantity
}

export { createPassiveAlert, addZeroToLeft, alertError, convertToCurrency, convertToNumber, createToast, cutDates, dateTimeToString, diffHours, formatDatetime, suspensivePoints, timeToString, calculateMaxQuantity }
