import Db from '../../utils/db'

const cartStore = {
  debug: false,
  state: {
    count: 0,
    total: 0,
    db: Db,
    items: [],
    showSideCart: false
  },
  addItem (newItem) {
    if (this.debug) console.log('addItem triggered with', newItem)
    this.state.db.saveItem(newItem)
    this.updateCount()
    this.updateTotal()
    return true
  },
  closeSideCart () {
    if (this.debug) console.log('closeSideCart triggered')
    this.state.showSideCart = false
    this.toggleSideCart()
  },
  empty () {
    this.state.db.truncateItems()
    this.state.items = []
    this.state.count = 0
    this.state.total = 0
  },
  loadItems () {
    if (this.debug) console.log('loadItems triggered')
    this.state.db.loadItems
      .then((items) => {
        this.updateAll(items)
      })
  },
  openSideCart () {
    if (this.debug) console.log('openSideCart triggered')
    this.state.showSideCart = true
    this.toggleSideCart()
  },
  removeItem (id) {
    if (this.debug) console.log('removeItem triggered with', id)
    this.state.db.removeItem(id).then((items) => {
      this.updateAll(items)
    })
  },
  toggleSideCart () {
    if (this.debug) console.log('toggleSideCart triggered')
    const body = document.querySelector('body')
    const overlay = document.querySelector('.sidebar-cart-overlay')
    const sidebar = document.querySelector('.sidebar-cart')
    const categoriesBar = document.querySelector('.categories-bar > .nav')

    if (this.state.showSideCart) {
      overlay.classList.remove('d-none')
      overlay.classList.add('d-block')
      sidebar.classList.add('show')
      body.classList.add('modal-open')
      if (categoriesBar) {
        categoriesBar.classList.add('overflow-hidden')
      }
    } else {
      overlay.classList.add('d-none')
      overlay.classList.remove('d-block')
      sidebar.classList.remove('show')
      body.classList.remove('modal-open')
      if (categoriesBar) {
        categoriesBar.classList.remove('overflow-hidden')
      }
    }
  },
  updateAll (items) {
    this.state.items = items
    this.updateCount()
    this.updateTotal()
  },
  updateCount () {
    if (this.debug) console.log('updateCount triggered')
    this.state.count = this.state.items.reduce((c, i) => c + parseFloat(i.quantity), 0)
  },
  updateTotal () {
    if (this.debug) console.log('updateTotal triggered')
    this.state.total = this.state.items.reduce((c, i) => c + parseFloat(i.total), 0)
  },
  updateItem (item) {
    if (this.debug) console.log('updateItem triggered with', item)
    this.state.db.updateItem(item)
    this.loadItems()
  }
}

cartStore.loadItems()

export default cartStore
